<template>
    <div class="pattern-editor">
        <DataEditor
            :value="pattern"
            :uimodel="uimodel"
            :show-label="false"
            :is-custom-editor="true"
            :immediate-update="true"
            @input="updateCursor"
            @restore="pattern=$cloneObject(uimodel.savedData)"
            class="pattern-editor-input"
            ref="patternEditor"
        />
        <v-subheader>Dimension Placeholders (click to insert)</v-subheader>
        <v-chip-group column class="pattern-placeholder-group">
            <v-chip 
                v-for="placeholder in placeholders"
                :key="placeholder"
                :disabled="pattern === undefined && uimodel.inheritedData != undefined"
                label
                class="pattern-placeholder"
                @click="setPlaceholder(placeholder)"
            >
                {{placeholder}}
            </v-chip>
        </v-chip-group>
    </div>
</template>
<script>
export default {

    inject: ["getPropertyData", "getProductType"],

    components: {
        DataEditor: () => import("../DataEditor.vue")
    },

    props: {

        value: {
            required: false
        },

        uimodel: {
            required: false,
            default: () => {return { domainData: null}}
        },

        inherited: {
            required: false,
            default: false,
        }

    },
    
    data(){
        return {
            lastCursorPosition: null,
            inputField: HTMLInputElement,
            pattern: this.value ?? this.uimodel.value,
            placeholders: [],
        }
    },

    async mounted(){
        await this.loadDimensions();

        this.$nextTick(() => {
            //get input of data editor
            let editor = this.$refs.patternEditor;
            let comp = editor?.$refs?.field;
            this.inputField = comp?.$refs?.input;
        });

    },

    watch: {

        pattern(pattern){
            this.$emit("input", pattern);
        },

        inherited(inherited){
            if(!inherited){
                this.$nextTick(() => {
                    //get input of data editor
                    let editor = this.$refs.patternEditor;
                    let comp = editor?.$refs?.field;
                    this.inputField = comp?.$refs?.input;
                });
            }
        }
    },

    methods:{

        setPlaceholder(text){
            //Get the input element and the last caret position
            this.inputField.focus();
            let selectStart = this.inputField.selectionStart;
            let selectEnd = this.inputField.selectionEnd;

            //Add the placeholder to the existing text in the input element
            //at the last cursor position
            let pattern = "";
            if(this.pattern && selectStart > 0) pattern += this.pattern.substring(0, selectStart);
            pattern += text;
            if(this.pattern) pattern += this.pattern.substring(selectEnd);
            this.pattern = pattern;

            //set the cursor position to the end of the current inserted placeholder
            //to keep writing afterwards
            this.lastCursorPosition = selectStart + text.length;
        },

        async loadDimensions(){
            let productType = this.getProductType();
            let dimensions = await this.getPropertyData({
                sourceType: "dimension",
                keySpace: "ProductType",
                productType
            });

            let placeholders = dimensions.map(dimension => { return "<" + dimension.key + ">"});
            placeholders.unshift("<productType>");
            placeholders.unshift("<connector>");
            this.placeholders = placeholders;
        },

        updateCursor(value){
            if(this.lastCursorPosition){
                //if a cursor position is defined, set the selection of the input field 
                //to the specifiec position
                this.$nextTick(() => {
                    this.inputField.focus();
                    let position = this.lastCursorPosition;
                    this.inputField.setSelectionRange(position, position);
                    this.lastCursorPosition = null;
                })
            }
            this.pattern = value
        }
    },
}
</script>

<style scoped>

.pattern-editor{
    background-color: transparent;
    min-width: 400px;
    max-width: 800px;
}

.pattern-editor > .pattern-editor-input::v-deep > .primitive-type-container > .field-wrap{
    width: 100%;
}

</style>